export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"661c27ce6bbd20002369bc1c",
				"661c27ce6bbd20002369bc1f"
			]
		},
		"661c27ce6bbd20002369bc1c": {
			"id": "661c27ce6bbd20002369bc1c",
			"name": "404",
			"pageUrl": "404"
		},
		"661c27ce6bbd20002369bc1f": {
			"id": "661c27ce6bbd20002369bc1f",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {
			"favicon_apple_152px": "https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/apple-touch-icon.png?v=2024-05-20T19:01:06.036Z",
			"favicon_win10_270px": "https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/android-chrome-512x512.png?v=2024-05-20T19:01:21.626Z",
			"favicon_32px": "https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/favicon-32x32.png?v=2024-05-20T19:02:10.953Z"
		}
	}
}