import { makeStyles } from '@material-ui/core/styles';

const signUpPageStyle = theme => ({
  signUpFormContainer: {
    maxWidth: '80%', /* Set a maximum width */
    margin: '0 auto',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '60%', /* Set a smaller maximum width for larger screens */
    }
  },
  formTitle: {
    fontSize: '24px',
    marginBottom: '10px',
    textAlign: 'center',
  },
  formDescription: {
    fontSize: '16px',
    marginBottom: '20px',
    textAlign: 'center',
  },
  formGroup: {
    marginBottom: '15px',
  },
  formLabel: {
    fontSize: '16px',
    display: 'block',
    marginBottom: '5px',
  },
  formInput: {
    width: '100%', /* Set width to 100% */
    padding: '10px',
    fontSize: '16px',
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  formButton: {
    width: '100%', /* Set width to 100% */
    padding: '10px',
    fontSize: '16px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  formMessage: {
    fontSize: '16px',
    color: '#007bff',
    textAlign: 'center',
    marginTop: '20px',
  },
});

export default signUpPageStyle;
