import React, { useState, useEffect} from 'react';
import theme from "theme";
import { Theme, Link, Icon, Text, LinkBox, Box, Image, Section, Input, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, SocialMedia } from "@quarkly/components";
import * as Components from "components";
import { GiFairyWings } from "react-icons/gi";
import { FiMenu } from "react-icons/fi";
import { AiOutlineMenu } from "react-icons/ai";


import axios from 'axios';

//Styling 
import signUpPageStyle from '../style/signupPageStyle';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => signUpPageStyle(theme));


const IndexPage = () => {
	const classes = useStyles();
	const [email, setEmail] = useState('');
	const [submitted, setSubmitted] = useState(false);


	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log('Email', email);
		try {
			const apiUrl = '/.netlify/functions/addToWaitlist';
	
			// MailJet called from Netlify function
			const response = await axios.post(apiUrl, { email: email });
			
			if (response.status === 200) {
				setEmail('');
				setSubmitted(true);
			} else {
				console.error('Error adding email to waitlist: ', response.statusText);
			}
		} catch (error) {
			console.error('Error adding email to waitlist: ', error);
		}
		setEmail('');
		setSubmitted(true);
	};


	// const handleSubmit = async (e) => {
	// 	e.preventDefault();
	// 	console.log('Email', email);
	// 	try {
	// 		// Add data to Firebase database
	// 		const type = "waitlist";
	// 		const date = new Date();
	// 		const month = date.getMonth() + 1;
	// 		const day = date.getDate();
	// 		const year = date.getFullYear();

	// 		const formattedDate = `${month}-${day}-${year}`;
	// 		const currDate = new Date().toLocaleDateString();
	// 		console.log(currDate);
	// 		console.log(formattedDate);
	// 		// const data = {
	// 		// 	email: email,
	// 		// 	date: currDate
	// 		// }
	// 		// const sanitizedEmail = email.replace(/[.#$[\]]/g, '_');
	// 		// const key = `${type}::${sanitizedEmail}`;
	// 		// const key = formattedDate;
	// 		// await set(ref(database, 'waitlist/' + key), data);
	// 		// console.log('Data from Firebase:', data);
	// 		setEmail('');
	// 		setSubmitted(true);
	// 		const apiUrl = process.env.REACT_APP_API_BASE_URL;

	// 		//MailJet called from backend server 
	// 		// await axios.post(`${apiUrl}/api/addToWaitlist`, { email: email });
	// 		await axios.post(`.netlify/functions/api/addToWaitlist`, { email: email });

	// 	} catch (error) {
	// 		console.error('Error adding document: ', error);
	// 	}
	// 	// Clear form fields and set submitted flag
	// 	// setName('');
	// 	setEmail('');
	// 	setSubmitted(true);
	//   };

	return (
	<Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				EventRent
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/favicon-32x32.png?v=2024-05-20T19:02:10.953Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/apple-touch-icon.png?v=2024-05-20T19:01:06.036Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/apple-touch-icon.png?v=2024-05-20T19:01:06.036Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/apple-touch-icon.png?v=2024-05-20T19:01:06.036Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/apple-touch-icon.png?v=2024-05-20T19:01:06.036Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/android-chrome-512x512.png?v=2024-05-20T19:01:21.626Z"} />
		</Helmet>
		<Section sm-padding="8px 0 8px 0" quarkly-title="Header-4">
			<Override slot="SectionContent" flex-direction="row" justify-content="space-between" />
			<Box
				display="flex"
				padding="12px 0"
				justify-content="space-between"
				align-items="flex-start"
				flex-direction="row"
				width="30%"
				sm-width="50%"
				sm-align-items="center"
				sm-flex-direction="row"
				sm-justify-content="flex-start"
				md-width="50%"
				lg-width="70%"
				md-justify-content="flex-start"
			>
				<LinkBox flex-direction="row" href="/index" display="flex" grid-gap="12px">
					<Icon
						category="gi"
						icon={GiFairyWings}
						color="#6d32ec"
						size="37px"
						margin="0px 15px 0px 0px"
					/>
					<Text
						margin="0"
						md-margin="0px 0 0px 0"
						text-align="left"
						font="--headline3"
						sm-margin="0px 0 0px 0"
						display="block"
					>
						EventRent
					</Text>
				</LinkBox>
			</Box>
			{/* <Components.QuarklycommunityKitMobileSidePanel
		menuPosition="full"
		breakpoint="lg"
		width="70%"
		sm-width="50%"
		md-width="50%"
		lg-width="30%"
	>
		<Override slot="Children" md-display="flex" />
		<Override
			slot="Content"
			padding="0px 0px 0px 0px"
			background="rgba(255, 255, 255, 0)"
			lg-background="#ffffff"
			lg-margin="0px 0px 0px 0px"
		/>
		<Override
			slot="Button Text"
			font="normal 600 16px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
			text-transform="uppercase"
			letter-spacing="1px"
			sm-font="normal 600 14px/1.5 -apple-system, system-ui, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif"
			sm-margin="0px 2px 0px 0px"
			lg-margin="0px 0px 0px 0px"
		/>
		<Override
			slot="Button Icon :closed"
			category="fi"
			icon="FiMenu"
			size="32px"
			padding="5px 7px 5px 7px"
			border-radius="50px"
		/>
		<Override
			slot="Button Icon"
			width="28px"
			height="28px"
			category="ai"
			icon="AiOutlineMenu"
			color="--dark"
			size="24px"
			lg-width="32px"
			lg-height="32px"
		/>
		<Override
			slot="Cross"
			lg-width="32px"
			lg-height="32px"
			size="32px"
			top="24px"
			right="24px"
		/>
		<Override slot="Wrapper" display="none" />
		<Box
			align-items="center"
			lg-justify-content="center"
			lg-align-items="flex-start"
			justify-content="flex-end"
			display="flex"
			lg-flex-direction="column"
			lg-margin="0px auto 0px auto"
			lg-min-width="300px"
			lg-max-width="1280px"
			lg-width="90%"
			lg-padding="24px 0px 48px 0px"
		>
			<Box
				display="none"
				lg-width="100%"
				lg-margin="0px 0px 24px 0px"
				lg-display="flex"
				lg-padding="12px 0px 12px 0px"
			>
				<LinkBox flex-direction="row" href="/index" display="flex" grid-gap="12px">
					<Image src="https://uploads.quarkly.io/6457b971d28fe100213a0f35/images/flower-logo-template.svg?v=2023-08-06T19:34:51.878Z" display="block" width="36px" height="36px" />
					<Text
						margin="0"
						md-margin="0px 0 0px 0"
						text-align="left"
						font="--lead"
						sm-margin="0px 0 0px 0"
						display="block"
						lg-font="--headline3"
					>
						Company
					</Text>
				</LinkBox>
			</Box>
			<Components.QuarklycommunityKitMenu
				display="flex"
				filterMode="exclude"
				filterPages="/index"
				grid-gap="8px"
				lg-flex-direction="column"
				lg-padding="6px 0px 6px 0px"
				lg-margin="0px 0px 24px 0px"
				align-items="center"
				flex-wrap="wrap"
				overflow-x="visible"
				overflow-y="visible"
				lg-align-items="flex-start"
			>
				<Override
					slot="link"
					color="--darkL2"
					hover-color="--primary"
					font="--lead"
					text-decoration-line="initial"
					transition="color 0.1s ease 0s"
					lg-font="--lead"
				/>
				<Override
					slot="item-active"
					border-width="0 0 2px 0"
					border-style="solid"
					border-color="--color-darkL2"
					lg-border-width="0 0 0 2px"
				/>
				<Override slot="item" padding="6px 8px 6px 8px" lg-padding="6px 12px 6px 12px" />
				<Override slot="link-active" cursor="default" color="--darkL2" hover-color="--darkL2" />
				<Override slot="link-about" />
			</Components.QuarklycommunityKitMenu>
			<Box
				width="25%"
				display="none"
				lg-width="100%"
				lg-flex-direction="column"
				lg-align-items="flex-start"
				lg-display="flex"
				justify-content="space-around"
				align-items="center"
				flex-wrap="wrap"
				lg-margin="0px 0px 0px 0px"
			>
				<SocialMedia
					instagram="https://instagram.com/instagram"
					margin="0px 0px 0px 0px"
					facebook="https://www.facebook.com/quarklyapp/"
					youtube="https://www.youtube.com/channel/UCK5bXs2L0bbSMQ82BQ3hIkw"
					lg-display="flex"
					lg-grid-gap="12px"
					display="none"
				>
					<Override slot="link-twitter" margin="0px 0px 0px 5px">
						<div />
					</Override>
					<Override
						slot="link"
						border-radius="50%"
						background="transparent"
						hover-color="--light"
						display="flex"
						margin="0 5px 0 5px"
						padding="5x 5px 5px 5px"
						width="32px"
						height="32px"
						align-items="center"
						justify-content="center"
					/>
					<Override slot="icon" size="32px" border-radius="50px" color="--grey" />
					<Override slot="link-facebook" margin="0px 5px 0px 0px">
						<div />
					</Override>
				</SocialMedia>
			</Box>
		</Box>
	</Components.QuarklycommunityKitMobileSidePanel> */}
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0px 60px 0px" flex-direction="row-reverse">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" order="0" />
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				align-items="center"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				order="-1"
				align-self="center"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-width="100%"
					align-self="flex-start"
					text-align="center"
				>
					Find event venues and sponsors{" "}
				</Text>
				<Text
					margin="0px 0px 48px 0px"
					color="--greyD3"
					font="--lead"
					lg-text-align="center"
					lg-width="100%"
					align-self="flex-start"
					text-align="center"
				>
					EventRent is an all-in-one marketplace for event spaces, connecting venues with sponsors and brands seamlessly. Join our waitlist now and be the first to experience the future of event hosting!
				</Text>
				<Box
					display="flex"
					lg-width="50%"
					lg-padding="0px 0px 0px 16px"
					md-width="100%"
					md-padding="0px 0px 0px 0px"
					width="100%"
					// align-self="flex-start"
					align-items="center"
					justify-content="center"
				>
					<Box
						// margin="0px 0px 0px 22px"
						width="75%"
						align-self="auto"
						flex="0 0 auto"
						display="block"
						// justify-content="center"
						// sm-align-items="center"
						// sm-justify-content="center"
					>
						<Text
							margin="0px 0px 8px 0px"
							color="--darkL2"
							font="--headline3"
							lg-text-align="left"
							text-align="center"
							sm-text-align="center"
						>
							Sign Up 
						</Text>
						{/* <Input display="block" placeholder-color="LightGray" background="white" width="100%" />
						<Button width="100%">
							Join Waitlist
						</Button> */}
						<div className={classes.signUpFormContainer}>
					{submitted ? (
								<p className={classes.formDescription}>Thank you for joining the waitlist!</p>
							) : (
					<form onSubmit={handleSubmit} className={classes.form}>
						<div className={classes.formGroup}>
							<input 
							type="email" 
							value={email} 
							onChange={(e) => setEmail(e.target.value)} 
							placeholder="Email"
							required 
							className={classes.formInput}
							/>
						</div>
					<Button type="submit" className={classes.formButton}>Join Waitlist</Button>
					</form>
						)}
				</div>
					</Box>
				</Box>
			</Box>
			<Box
				display="flex"
				width="40%"
				justify-content="flex-end"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-justify-content="center"
				lg-padding="0px 0px 0px 0px"
			>
				<Image
					src="https://uploads.quarkly.io/661c27ce6bbd20002369bc17/images/EventRent_%20copy.png?v=2024-05-20T18:28:05.840Z"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					height="100%"
					srcSet="https://smartuploads.quarkly.io/661c27ce6bbd20002369bc17/images/EventRent_%20copy.png?v=2024-05-20T18%3A28%3A05.840Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/661c27ce6bbd20002369bc17/images/EventRent_%20copy.png?v=2024-05-20T18%3A28%3A05.840Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/661c27ce6bbd20002369bc17/images/EventRent_%20copy.png?v=2024-05-20T18%3A28%3A05.840Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/661c27ce6bbd20002369bc17/images/EventRent_%20copy.png?v=2024-05-20T18%3A28%3A05.840Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/661c27ce6bbd20002369bc17/images/EventRent_%20copy.png?v=2024-05-20T18%3A28%3A05.840Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/661c27ce6bbd20002369bc17/images/EventRent_%20copy.png?v=2024-05-20T18%3A28%3A05.840Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/661c27ce6bbd20002369bc17/images/EventRent_%20copy.png?v=2024-05-20T18%3A28%3A05.840Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section background="--color-light" padding="50px 0 50px 0" quarkly-title="Footer-22">
			<Box
				display="flex"
				justify-content="space-between"
				border-color="#232a44"
				md-flex-direction="column"
				align-items="center"
				lg-flex-direction="column"
			>
				<Box
					display="flex"
					md-margin="0px 0px 25px 0px"
					sm-flex-direction="column"
					align-items="center"
					lg-margin="0px 0px 25px 0px"
					md-flex-direction="column"
				>
					<LinkBox
						display="flex"
						margin="0px 70px 0px 0px"
						md-margin="0px 0 30px 0px"
						flex-direction="row"
						align-items="flex-end"
					>
						<Icon
							category="gi"
							icon={GiFairyWings}
							color="#6d32ec"
							size="37px"
							margin="0px 15px 0px 0px"
						/>
						<Text margin="0px 0px 0px 0px" font="normal 500 28px/1 --fontFamily-serifTimes">
							EventRent
						</Text>
					</LinkBox>
					<Box
						display="flex"
						md-margin="0px 0px 15px 0px"
						sm-flex-direction="column"
						justify-content="flex-start"
						align-items="center"
						grid-gap="24px"
					>
						<Box display="flex" grid-gap="8px">
							<Link
								href="tel:+9877654321223"
								color="--darkL2"
								text-decoration-line="initial"
								font="normal 300 16px/1.5 --fontFamily-sans"
								display="block"
								margin="0px 0px 0px 0px"
								sm-margin="0px 0 5px 0px"
								hover-color="#6d32ec"
							/>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661c27ce6bbd20002369bc15"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>
// });

)};

export default IndexPage;